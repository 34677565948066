import * as React from 'react';

import { BookingWidgetThemeContext } from 'contexts/BookingWidgetThemeContext';
import { getCustomComponent } from 'components/Themes';
import { PMPCategoryListViewProps } from 'lib/themes/themes';

export const PMPCategoryListView = (props: PMPCategoryListViewProps) => {
  const bookingWidgetTheme = React.useContext(BookingWidgetThemeContext);

  const CustomComponent = getCustomComponent(bookingWidgetTheme, 'PMPCategoryListView');
  return <CustomComponent {...props} />;
};
