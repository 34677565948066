import * as React from 'react';
import Head from 'next/head';
import { useSelector } from 'react-redux';
import { ReduxState } from 'ducks';
import { TopPage } from '@nutmeglabs/falcon-ui';

interface Props {
  topPageData: TopPage;
}

export const OgpTags = ({ topPageData }: Props) => {
  const companyName = useSelector((state: ReduxState) => state.server.settings.all)?.supplier_name;

  const title = topPageData?.pageDescription || companyName;
  const siteName = `${
    topPageData?.pageDescription ? `${topPageData?.pageDescription} ` : ''
  }${companyName}`;

  const ogpImageUrl = topPageData?.keyVisualImageUrls?.length
    ? topPageData?.keyVisualImageUrls[0]
    : '';

  const description = topPageData?.catchPhraseDescription || '';

  return (
    <Head>
      <meta name="title" content={title} />
      <meta property="og:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:image" content={ogpImageUrl} />
    </Head>
  );
};
