import * as React from 'react';
import clsx from 'clsx';

import { useBounds } from 'hooks/useBounds';

import styles from './CardSlider.module.css';

type Props<T> = {
  data: T[];
  renderElement: (t: T) => React.ReactNode;
  centerNarrowContent?: boolean;
};

export function CardSlider<T>({ centerNarrowContent, data, renderElement }: Props<T>) {
  const [sliderOffset, setSliderOffset] = React.useState(0);
  const [firstItemRef, firstItemBounds] = useBounds<HTMLLIElement>();
  const [sliderListRef, sliderListBounds] = useBounds<HTMLUListElement>();

  const itemWidth = firstItemBounds.width + 32;
  const sliderTotalWidth = data.length * itemWidth;
  const sliderTotalRight = -sliderOffset + sliderListBounds.left + sliderTotalWidth + 16;
  const sliderVisibleRegionRight = sliderListBounds.right - 16;
  const sliderVisibleRegionWidth = sliderListBounds.right - sliderListBounds.left - 32;
  const remainingStripWidth = sliderTotalRight - sliderVisibleRegionRight;

  const handlePrev = () => {
    // If we are already at the left bounds, do nothing
    if (sliderOffset === 0) {
      return;
    }

    // Reduce offset by the minimum between the current offset or the slider list width.
    const delta = Math.min(sliderOffset, sliderVisibleRegionWidth);

    // Apply delta and round down to a multiple of item width
    const newOffset = Math.floor((sliderOffset - delta) / itemWidth) * itemWidth;

    setSliderOffset(newOffset);
  };

  const handleNext = () => {
    // If we are already at the right bounds or the entire group of items fits onscreen, do nothing.
    if (sliderTotalRight <= sliderVisibleRegionRight) {
      return;
    }

    // Increase offset by the minimum of the remaining strip width or the space between the arrows.
    const delta = Math.min(remainingStripWidth, sliderVisibleRegionWidth);

    const newOffset =
      delta === remainingStripWidth
        ? sliderOffset + delta
        : Math.floor((sliderOffset + delta) / itemWidth) * itemWidth;

    setSliderOffset(newOffset);
  };

  const itemStyle = {
    transform: `translateX(-${sliderOffset}px)`,
  };

  const showNextButton = sliderTotalRight > sliderVisibleRegionRight + 16;
  const showPrevButton = !!sliderOffset;

  return (
    <div className={styles['c-card-slider']}>
      <div
        style={showPrevButton ? {} : { display: 'none' }}
        className={styles['c-card-slider__prev']}
      >
        <button onClick={handlePrev} />
      </div>
      <div
        style={showNextButton ? {} : { display: 'none' }}
        className={styles['c-card-slider__next']}
      >
        <button onClick={handleNext} />
      </div>
      <ul
        className={clsx({
          [styles['c-card-slider__list']]: true,
          [styles['center']]: centerNarrowContent && !showNextButton && !showPrevButton,
        })}
        style={{ overflowX: 'hidden' }}
        ref={sliderListRef}
      >
        {data.map((elem, idx) => (
          <li
            style={itemStyle}
            key={idx}
            className={styles['c-card-slider__list__item']}
            ref={idx === 0 ? firstItemRef : null}
          >
            {renderElement(elem)}
          </li>
        ))}
      </ul>
    </div>
  );
}
