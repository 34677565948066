import * as React from 'react';

import { BookingWidgetThemeContext } from 'contexts/BookingWidgetThemeContext';
import { PMPKeyVisualViewProps } from 'lib/themes/themes';
import { getCustomComponent } from 'components/Themes';

export const PMPKeyVisualView = (props: PMPKeyVisualViewProps) => {
  const bookingWidgetTheme = React.useContext(BookingWidgetThemeContext);

  const CustomComponent = getCustomComponent(bookingWidgetTheme, 'PMPKeyVisualView');

  return <CustomComponent {...props} />;
};
